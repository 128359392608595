<script setup lang="ts">
import { RouterView } from 'vue-router';
</script>

<template>
  <RouterView />
</template>

<!-- TODO: Can we use the Raleway font also via https://unocss.dev/presets/web-fonts ?-->
<style lang="scss">
:root {
  font-family: Raleway, sans-serif, ui-sans-serif;
}
</style>
