import { FeatureFlag, FeatureFlags } from '@/@core/models/FeatureFlags';

export class FeatureFlagClient {
  constructor(private flags: Partial<FeatureFlags>) {}

  public getFlag(flagKey: FeatureFlag, defaultValue: boolean = false): boolean {
    return this.flags[flagKey] ?? defaultValue;
  }

  public setFlags(flags: Partial<FeatureFlags>): void {
    this.flags = flags;
  }
}
