import { z } from 'zod';

export const FEATURE_FLAGS_SCHEMA = z.object({
  enableHomepage: z.boolean().optional(),
  enableSummarize: z.boolean().optional(),
  enableQa: z.boolean().optional(),
  enableTranslate: z.boolean().optional(),
  enableTranscribe: z.boolean().optional(),
  enableUsecases: z.boolean().optional(),
});
export type FeatureFlags = z.infer<typeof FEATURE_FLAGS_SCHEMA>;

export type FeatureFlag = keyof FeatureFlags;
